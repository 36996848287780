<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import useGameMode from '~/composables/GameMode/GameMode'
  import useTranslation from '~/composables/Translation/Translation'
  import useContentPage from '~/composables/ContentPage/ContentPage'
  import { ContentPageId, ContentPageListContent } from '~/model/ContentPage/ContentPage'
  import { useGameContentStore } from '~/store/GameContent'
  import { computed } from '#imports'

  const gameContentStore = useGameContentStore()

  const { pageList } = storeToRefs(gameContentStore)
  const { t } = useTranslation()
  const { toContentPage } = useContentPage()

  const teachingResources = computed<ContentPageListContent | undefined>(() => {
    return pageList.value.find(({ id }) => {
      return id === ContentPageId.TeachingResources
    })
  })

  const project = computed<ContentPageListContent | undefined>(() => {
    return pageList.value.find(({ id }) => {
      return id === ContentPageId.Project
    })
  })
</script>

<template>
  <div class="c-navigation-footer u-typography-root">
    <ul class="c-navigation-footer__list u-reset">
      <li class="c-navigation-footer__entry">
        <NuxtLink :to="toContentPage(ContentPageId.TeachingResources)" class="c-navigation-footer__link u-reset">
          <span>{{ teachingResources.menuTitle ?? teachingResources.name }}</span>
        </NuxtLink>
      </li>
      <li class="c-navigation-footer__entry">
        <NuxtLink :to="toContentPage(ContentPageId.Project)" class="c-navigation-footer__link u-reset">
          <span>{{ project.menuTitle ?? project.name }}</span>
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  @use 'sass:color';
  @use '@nirazul/scss-utils' as utils;
  @use '/assets/scss/base/mq' as mq;
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/util/transition' as trs;
  @use '/assets/scss/base/typography/typography' as type;

  $height: 56px;

  .c-navigation-footer {
    color: col.$monochrome-lead;
    background-color: color.change(col.$monochrome-white, $alpha: 0.9);
    padding-right: var(--scroll-lock);

    @include mq.media('>xs') {
      height: $height;
    }
  }

  .c-navigation-footer__list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    column-gap: 32px;
    width: 100%;
    height: 100%;

    @include mq.media('xs') {
      padding: 16px 0;
    }
  }

  .c-navigation-footer__link {
    @include type.button-small;
    cursor: pointer;
    color: col.$monochrome-black;
    transition: trs.$default;

    &:not(.u-router-link-exact-active) {
      @include utils.has-focus {
        color: col.$variant-cyan-dark;
      }
    }
  }
</style>
